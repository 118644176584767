// Download content to filename via browser
// Inputs:
//  content: Content to download
//  filename: Filename of downloaded file
//  contentType: 'text/json', 'text/text', 'text/csv', or other MIME type
const download = function (content, filename, contentType) {
  if (!contentType) contentType = "application/octet-stream";
  var a = document.createElement("a");
  var blob = new Blob([content], { type: contentType });
  a.href = window.URL.createObjectURL(blob);
  a.download = filename;
  a.click();
};

export { download };
