const errorStrings = {
  INTERNET_ERROR: 'internetError',
  FIRESTORE_ERROR: 'firestore/error',
  FETCH_FAIL: 'fetch/fail',
  PERMISSION_DENIED: 'permission-denied',
  EXPIRED: 'expired',
  TOKEN_EXPIRED: 'auth/id-token-expired'
}

export default errorStrings;
