import { useEffect } from "react";
import firebase from "firebase/app";
import "firebase/firestore";
import actionCreator from "../reducer/actionCreator";
import constants from "../utility/constants";
import actionStrings from "../reducer/actionStrings";

const useCollectionList = (
  collectionListKey,
  actionString,
  dispatch,
  eventKey
) => {
  const db = firebase.firestore();
  useEffect(() => {
    if (!eventKey) return null;
    let unsubscribe = db
      .collection(constants.EVENT_LIST_KEY)
      .doc(eventKey)
      .collection(collectionListKey)
      .onSnapshot(
        (querySnapshot) => {
          let list = [];
          querySnapshot.forEach((rec) => {
            list.push(rec.data());
          });
          dispatch(actionCreator(actionString, { err: false, list }));
        },
        (err) => {
          dispatch(
            actionCreator(actionStrings.ERROR, {
              err: true,
              errorCode: err.code,
            })
          );
        }
      );
    return () => {
      unsubscribe();
    };
  }, [eventKey, collectionListKey, actionString, dispatch, db]);
};

export default useCollectionList;
