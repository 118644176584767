// import firebase from "firebase/app";
// import "firebase/firestore";
import as from "../reducer/actionStrings";
import actionCreator from "../reducer/actionCreator";
import errorStrings from "../utility/errorStrings";
import actionStrings from "../reducer/actionStrings";
import constants from "../utility/constants";

const getUserList = (firebaseDefault, user_id) => (dispatch) => {
  const db = firebaseDefault.firestore();
  db.collection(constants.USER_LIST_KEY).onSnapshot(
    (querySnapshot) => {
      let list = [];
      querySnapshot.forEach((rec) => {
        list.push(rec.data());
      });
      dispatch(
        actionCreator(actionStrings.SET_USER_LIST, { err: false, list })
      );
    },
    (err) => {
      dispatch(
        actionCreator(as.ERROR, { errorCode: errorStrings.FIRESTORE_ERROR })
      );
    }
  );
};

export default getUserList;
