import actionCreator from "../reducer/actionCreator";
import constants from "../utility/constants";
import actionStrings from "../reducer/actionStrings";

/**
 * Login thunk to read the user information from Firestore and inject
 * it into the user claims in the idToken JWT.
 * @param authResult  Result from the signinSuccessWithAuthResult
 *                    callback from the StyledFirebaseUI component.
 *                    The updated idToken and injected claims are dispatched
 *                    to the reducer after they're retrieved from Firebase
 * @param firebaseDefault
 * @returns {function(*=, *): void}
 */
const loginThunk = (authResult, firebaseDefault) => (dispatch) => {
  let { user, additionalUserInfo } = authResult;
  dispatch(
    actionCreator(actionStrings.SET_FB_USER, {
      fbUser: user,
      fbAdditionalUserInfo: additionalUserInfo,
    })
  );
  user.getIdTokenResult().then((idTokenResult) => {
    let idToken = idTokenResult.token;
    dispatch(actionCreator(actionStrings.SET_ID_TOKEN, { idToken }));
  });

  let userRef = firebaseDefault.firestore().collection(constants.USER_LIST_KEY);
  userRef
    .doc(user.email)
    .get()
    .then((doc) => {
      // ... user information exists, grab the admin and expiry information
      if (doc.exists) {
        let userDoc = doc.data();
        dispatch(
          actionCreator(actionStrings.SET_FIRESTORE_USER, { fsUser: userDoc })
        );

        // For V1 we're only going to allow the admin user to create accounts for users
        // ... this is a new user; set up data payload and create the new user
      } else {
        dispatch(actionCreator(actionStrings.INVALID_USER));
      }
    });
};

export default loginThunk;
