import { getUnixTime } from "date-fns";
import constants from "./constants";

const refundComplete = (firebaseApp, event, transaction) => {
  let refundDate;
  if (transaction.refundDate === "") {
    refundDate = getUnixTime(new Date());
  } else {
    refundDate = "";
  }
  firebaseApp
    .firestore()
    .collection(constants.EVENT_LIST_KEY)
    .doc(event.key)
    .collection(constants.TRANSACTION_LIST_KEY)
    .doc(transaction.key)
    .update({ refundDate });
};

export default refundComplete;
