import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Login from "../Login/Login";
import Event from "../Event/Event";
import getUnixTime from "date-fns/getUnixTime";
import actionCreator from "../../reducer/actionCreator";
import actionStrings from "../../reducer/actionStrings";
import errorStrings from "../../utility/errorStrings";
import getEventList from "../../thunks/getEventList";
import constants from "../../utility/constants";
import useCollectionList from "../../hooks/useCollectionList";
import "./App.css";
import getUserList from "../../thunks/getUserList";

const App = () => {
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  const isUnauthorized = useSelector((state) => state.unauthorized);
  const firebaseDefault = useSelector((state) => state.firebaseDefault);
  const eventKey = useSelector((state) => state.eventKey);
  const fsUser = useSelector((state) => state.fsUser);
  const dispatch = useDispatch();

  useCollectionList(
    constants.STUDENT_LIST_KEY,
    actionStrings.SET_STUDENT_LIST,
    dispatch,
    eventKey
  );
  useCollectionList(
    constants.TRANSACTION_LIST_KEY,
    actionStrings.SET_TRANSACTION_LIST,
    dispatch,
    eventKey
  );

  useEffect(() => {
    let now = getUnixTime(new Date());
    if (fsUser?.expiry <= now) {
      dispatch(
        actionCreator(actionStrings.ERROR, { errorCode: errorStrings.EXPIRED })
      );
    }
  }, [dispatch, fsUser]);

  useEffect(() => {
    if (!isLoggedIn) return;
    dispatch(getEventList(firebaseDefault));
  }, [isLoggedIn, dispatch, firebaseDefault]);

  useEffect(() => {
    if (!isLoggedIn || !fsUser || !fsUser.admin) return;
    dispatch(getUserList(firebaseDefault));
  });

  return isLoggedIn && !isUnauthorized ? <Event /> : <Login />;
};

export default App;
