import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardHeader, Row, Col } from "reactstrap";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import styles from "./Login.css";
import Footer from "../Footer/Footer";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import actionCreator from "../../reducer/actionCreator";
import actionStrings from "../../reducer/actionStrings";
import loginThunk from "../../thunks/loginThunk";
import "./firebaseui-styling.global.css";
import "./firebaseui-styling.global.css";
import "./Login.css";
import "firebaseui/dist/firebaseui.css";

/**
 * Login component leveraging Firebase authentication and the react-firebaseui library
 *
 * Login flow:
 *   react-firebaseui prompts user for credentials: Google or email / password (phone number login is planned too)
 *   signInSuccessWithAuthResult is called with authResult object on successful login
 *   idToken is saved in idToken component state variable
 *   useEffect retrieves claims from idTokenResult
 *
 *   if user doesn't exist in Firestore
 *     create a new user in Firestore
 *   Retrieve user information
 *   setClaims API call with admin and expiry values to update Firebase claims of this user
 *   When claims are set, refresh idToken
 *   useEffect sees the change in idToken and retrieves new claims from idTokenResult
 *
 *   Claims available:
 *    Used:   admin, expiry, name, user_id (uid in auth object for Firestore rules)
 *    Unused: picture (URL), iss, aud, auth_time, sub,
 *            iat, exp, email_verified
 *
 * @returns {JSX.Element}
 */

const Login = () => {
  const [inProcess, setInProcess] = useState(false);
  const [emailVerificationSent, setEmailVerificationSent] = useState(false);
  const [email, setEmail] = useState("");
  const firebaseDefault = useSelector((state) => state.firebaseDefault);
  const isUnauthorized = useSelector((state) => state.unauthorized);
  const dispatch = useDispatch();

  const signInSuccessWithAuthResult = (authResult) => {
    if (authResult.user.emailVerified) {
      setInProcess(true);
      dispatch(loginThunk(authResult, firebaseDefault));
      return false;
    } else {
      authResult.user.sendEmailVerification();
      setEmail(authResult.user.email);
      setInProcess(false);
      setEmailVerificationSent(true);
    }
  };

  const signInFailure = (error) => {
    dispatch(
      actionCreator(actionStrings.ERROR, { errorCode: "auth/signin-failure" })
    );
  };

  const uiConfig = {
    callbacks: {
      signInSuccessWithAuthResult,
      signInFailure,
    },

    // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
    signInFlow: "popup",
    signInOptions: [
      // // Leave the lines as is for the providers you want to offer your users.
      // {
      //   provider: firebase?.auth.EmailAuthProvider.PROVIDER_ID,
      //   signInMethod:
      //     firebase?.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD,
      //   // signInMethod: firebase?.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD
      // },
      {
        provider: firebase?.auth.GoogleAuthProvider.PROVIDER_ID,
      },
      // firebase.auth.PhoneAuthProvider.PROVIDER_ID
    ],

    // Terms of service url.
    tosUrl: "https://asbtickets.com",
    // Privacy policy url.
    privacyPolicyUrl: "https://asbtickets.com",
  };

  if (isUnauthorized) {
    return (
      <Card>
        <CardHeader className="text-center">
          <h1>ASB Event Ticketing</h1>
          <h4>Registrar login</h4>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={{ size: 6, offset: 3 }}>
              <h5 className="text-center">
                You are not authorized for this system.
              </h5>
            </Col>
          </Row>
        </CardBody>
        <Footer />
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader className="text-center">
        <h1>ASB Event Ticketing</h1>
        <h4>Registrar login</h4>
      </CardHeader>
      <CardBody>
        {emailVerificationSent ? (
          <Row>
            <Col md={{ size: 6, offset: 3 }}>
              <h5 className="text-center">
                An email verification link has been sent to {email}. You must
                verify this email address before using ASB Event Ticketing.
              </h5>
            </Col>
          </Row>
        ) : null}
        {firebase && !inProcess && !emailVerificationSent ? (
          <StyledFirebaseAuth
            className={styles.firebaseUi}
            uiConfig={uiConfig}
            firebaseAuth={firebase.auth()}
          />
        ) : (
          <h3 className="text-center">Loading...</h3>
        )}
      </CardBody>
      <Footer />
    </Card>
  );
};

export default Login;
