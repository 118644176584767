import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

const ModalConfirm = props => {
  const {
    modalFlag,
    title,
    body,
    confirmButtonLabel,
    className,
    confirmCallback,
    cancelCallback
  } = props;

  const cancel = () => cancelCallback();
  const confirm = () => confirmCallback();

  return (
    <Modal isOpen={modalFlag} modalTransition={{ timeout: 700 }} backdropTransition={{ timeout: 1300 }}
           toggle={cancel} className={className}>
      <ModalHeader toggle={cancel}>{title}</ModalHeader>
      <ModalBody>{body}</ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={confirm}>{confirmButtonLabel}</Button>{' '}
        <Button color="secondary" onClick={cancel}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
}

export default ModalConfirm;
