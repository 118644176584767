import getUnixTime from "date-fns/getUnixTime";

const initEvent = (key) => ({
  key,
  name: "New event",
  location: "Patrick Henry High School Gym",
  fromTime: getUnixTime(new Date()),
  toTime: getUnixTime(new Date()),
  priceWithASB: "",
  priceWithoutASB: "",
});

export default initEvent;
