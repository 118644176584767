import sha256 from "crypto-js/sha256";

const initStudent = (rec) => {
  return {
    key: rec[0],
    id: rec[0],
    lastName: rec[1],
    firstName: rec[2],
    grade: rec[3],
    classOf: rec[4],
    password: sha256(rec[5]).toString(),
    active: true,
    studentTransaction: "",
    guestTransaction: "",
  };
};

export default initStudent;
