import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Form, FormGroup, Label, Input, Button } from "reactstrap";
import { format, fromUnixTime, getUnixTime } from "date-fns";
import constants from "../../utility/constants";
import { deleteCollection } from "../../utility/deleteCollection";
import ModalConfirm from "../ModalConfirm/ModalConfirm";
import "./Information.css";

const Information = () => {
  const [event, setEvent] = useState(null);

  const [reset, setReset] = useState(false);
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [fromTime, setFromTime] = useState("");
  const [toTime, setToTime] = useState("");
  const [priceWithASB, setPriceWithASB] = useState("");
  const [priceWithoutASB, setPriceWithoutASB] = useState("");

  const [dirty, setDirty] = useState(false);

  const [timeError, setTimeError] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [deleteError, setDeleteError] = useState(false);
  const [confirmDeleteResults, setConfirmDeleteResults] = useState(false);

  const eventList = useSelector((state) => state.eventList);
  const eventKey = useSelector((state) => state.eventKey);

  const firebase = useSelector((state) => state.firebaseDefault);
  const [db] = useState(firebase.firestore());

  useEffect(() => {
    if (!eventKey || !eventList) return;
    setEvent(eventList.find((event) => event.key === eventKey));
  }, [eventKey, eventList]);

  useEffect(() => {
    if (!event) return;
    setName(event.name);
    setLocation(event.location);
    setFromTime(toTimeText(event.fromTime));
    setToTime(toTimeText(event.toTime));
    setPriceWithASB(event.priceWithASB);
    setPriceWithoutASB(event.priceWithoutASB);
  }, [event, reset]);

  useEffect(() => {
    const f = new Date(fromTime).getTime();
    const t = new Date(toTime).getTime();
    setTimeError(f - t > 0);
  }, [fromTime, toTime]);

  const onChange = (cb) => (evt) => {
    cb(evt.currentTarget.value);
    setDirty(true);
  };

  const save = () => {
    let items = {
      name,
      location,
      fromTime: getUnixTime(new Date(fromTime)),
      toTime: getUnixTime(new Date(toTime)),
      priceWithASB,
      priceWithoutASB,
    };
    let newEvent = { ...event, ...items };
    db.collection(constants.EVENT_LIST_KEY)
      .doc(newEvent.key)
      .set(newEvent)
      .then((err) => {
        setDirty(false);
      });
  };

  const cancel = () => {
    setReset(!reset);
    setDirty(false);
  };

  const del = () => {
    setConfirmModal(true);
  };

  const cancelDel = () => {
    setConfirmModal(false);
  };

  const cancelDelResults = () => {
    setConfirmDeleteResults(false);
  };

  const toTimeText = (time) => {
    return format(fromUnixTime(time), "yyyy-MM-dd'T'HH:mm");
  };

  const confirmDel = () => {
    const eventRef = db.collection(constants.EVENT_LIST_KEY).doc(event.key);
    let deleteThese = [
      eventRef.collection(constants.STUDENT_LIST_KEY),
      eventRef.collection(constants.BID_LIST_KEY),
    ];
    deleteThese = deleteThese.map((ref) => deleteCollection(db, ref, 100));
    Promise.all(deleteThese)
      .then(() => {
        eventRef.delete().then(() => {
          setConfirmModal(false);
        });
      })
      .catch((error) => {
        setDeleteError("Unable to delete this event (" + error.code + ")");
        setConfirmModal(false);
        setTimeout(() => setDeleteError(null), 8000);
      });
  };

  const confirmDelResults = () => {
    const ref = db
      .collection(constants.EVENT_LIST_KEY)
      .doc(eventKey)
      .collection(constants.AUDIT_LIST_KEY);
    deleteCollection(db, ref, 100)
      .then(() => {
        setConfirmDeleteResults(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  if (!event) return null;
  return (
    <Row className="information-panel">
      <Col md={1}>&nbsp;</Col>
      <Col md={7}>
        <Form>
          <FormGroup row>
            <Label for="name" md={5}>
              Event name:
            </Label>
            <Col md={7}>
              <Input
                type="text"
                name="name"
                id="name"
                data-page="event"
                onChange={onChange(setName)}
                value={name}
                placeholder="Event name"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="location" md={5}>
              Location:
            </Label>
            <Col md={7}>
              <Input
                type="text"
                name="location"
                id="location"
                data-page="event"
                onChange={onChange(setLocation)}
                value={location}
                placeholder="Event location"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="fromTime" md={5}>
              Starting time:
            </Label>
            <Col md={7}>
              <Input
                type="datetime-local"
                name="fromTime"
                id="fromTime"
                data-page="event"
                className={timeError ? "invalid" : ""}
                value={fromTime}
                onChange={onChange(setFromTime)}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="fromTime" md={5}>
              Ending time:
            </Label>
            <Col md={7}>
              <Input
                type="datetime-local"
                name="toTime"
                id="toTime"
                data-page="event"
                className={timeError ? "invalid" : ""}
                value={toTime}
                onChange={onChange(setToTime)}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="priceWithASB" md={5}>
              Current price with ASB card:
            </Label>
            <Col md={7}>
              <Input
                type="number"
                name="priceWithASB"
                id="priceWithASB"
                data-page="event"
                onChange={onChange(setPriceWithASB)}
                value={priceWithASB}
                placeholder="Price with ASB card"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="priceWithoutASB" md={5}>
              Current price without ASB card:
            </Label>
            <Col md={7}>
              <Input
                type="number"
                name="priceWithoutASB"
                id="priceWithoutASB"
                data-page="event"
                onChange={onChange(setPriceWithoutASB)}
                value={priceWithoutASB}
                placeholder="Price without ASB card"
              />
            </Col>
          </FormGroup>
          <Row>
            <Col md={5}>&nbsp;</Col>
            <Col md={4}>
              <Button onClick={del} color="danger">
                Delete event
              </Button>
              {deleteError ? <h6 className="error">{deleteError}</h6> : null}
            </Col>
          </Row>
        </Form>
      </Col>
      <Col md={3} className="text-right">
        <Row>
          <Col>
            <Button
              onClick={save}
              color="primary"
              disabled={!dirty || timeError}
              className="control-buttons"
            >
              Save
            </Button>
            <Button
              onClick={cancel}
              color="primary"
              disabled={!dirty || timeError}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Col>
      <ModalConfirm
        modalFlag={confirmModal}
        body="Are you sure you want to delete this event?  This will delete all of the ticket sales. Once deleted the event can't be recovered."
        confirmButtonLabel="Yes"
        className=""
        confirmCallback={confirmDel}
        cancelCallback={cancelDel}
        title="Confirm delete event"
      />
      <ModalConfirm
        modalFlag={confirmDeleteResults}
        body="Are you sure you want to delete the results for this event?  These results can't be recovered."
        confirmButtonLabel="Yes"
        className=""
        confirmCallback={confirmDelResults}
        cancelCallback={cancelDelResults}
        title="Confirm delete event"
      />
    </Row>
  );
};

export default Information;
