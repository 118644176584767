const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
const R = require('ramda');

const getRandomString = length => {
  let string = R.range(0, length);
  string = string.map(() => parseInt(Math.random()*characters.length, 10));
  string = string.map(idx => characters[idx]);
  return string.join('');
}

export default getRandomString;
