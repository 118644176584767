import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  ListGroupItemText,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import { produce } from "immer";
import "./Users.css";
import constants from "../../utility/constants";
import ModalConfirm from "../ModalConfirm/ModalConfirm";
import { getRef } from "../../utility/getRef";
import firebase from "firebase/app";

const Users = () => {
  const [userEmail, setUserEmail] = useState(null);
  const [user, setUser] = useState(null);

  const [newUser, setNewUser] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [admin, setAdmin] = useState(false);
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);

  const [dirty, setDirty] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [db] = useState(firebase.firestore());

  const sortByName = (a, b) =>
    a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
  const sortList = (list, comparator) => {
    return produce(list, (draft) => {
      return draft.sort(comparator);
    });
  };

  const userList = useSelector((state) => {
    return sortList(state.userList, sortByName);
  });

  useEffect(() => {
    if (!userEmail || !userList) return;
    setUser(userList.find((of) => of.email === userEmail));
  }, [userEmail, userList]);

  useEffect(() => {
    if (!user) return;
    setName(user.name);
    setEmail(user.email);
    setAdmin(user.admin);
    setDirty(false);
  }, [user]);

  useEffect(() => {
    setDisableSaveBtn(name.length === 0 || email.length === 0);
  }, [name, email]);

  const userItemClicked = (evt) => {
    let key = evt.currentTarget.getAttribute("data-key");
    setUserEmail(key);
  };

  const onChange = (setter) => (evt) => {
    setter(evt.currentTarget.value);
    setDirty(true);
  };

  const onChangeCheckbox = (evt) => {
    setAdmin((admin) => !admin);
    setDirty(true);
  };

  const add = () => {
    setName("");
    setEmail("");
    setAdmin(false);
    setDirty(true);
    setUserEmail("");
    setUser(null);
    setNewUser(true);
  };

  const del = () => {
    setConfirmModal(true);
  };

  const confirmUserDelete = () => {
    let ref = getRef(db, [constants.USER_LIST_KEY, userEmail]);
    ref.delete().then(() => {
      setEmail("");
      setName("");
      setAdmin(false);
      setUser(null);
    });
    setConfirmModal(false);
  };

  const cancelUserDelete = () => {
    setConfirmModal(false);
  };

  const save = () => {
    db.collection(constants.USER_LIST_KEY)
      .doc(email)
      .set({ name, email, admin })
      .then(() => {
        setDirty(false);
        setNewUser(false);
      });
  };

  const cancel = () => {
    if (newUser) {
      setName("");
      setEmail("");
      setAdmin(false);
    } else {
      setName(user.name);
      setEmail(user.email);
      setAdmin(user.admin);
    }
    setDirty(false);
    setNewUser(false);
  };

  return (
    <Row className="candidate-panel">
      <Col md={4}>
        <Card>
          <CardHeader>
            <h4>User list</h4>
          </CardHeader>
          <CardBody>
            <ListGroup>
              {userList.map((o) => (
                <ListGroupItem
                  className="user-item"
                  data-key={o.email}
                  key={o.email}
                  onClick={userItemClicked}
                  active={o.email === user?.email}
                >
                  <ListGroupItemText>
                    <span className="user-name">
                      {o.name}{" "}
                      {o.admin ? (
                        <span className="admin-italic">(Administrator)</span>
                      ) : (
                        ""
                      )}
                    </span><br/>
                    <span className="user-email">{o.email}</span>
                  </ListGroupItemText>
                </ListGroupItem>
              ))}
            </ListGroup>
          </CardBody>
        </Card>
      </Col>
      <Col md={8}>
        <Row className="bottom-spacer">
          <Col md={6}>
            <Button
              onClick={add}
              color="primary"
              disabled={dirty}
              className="control-buttons"
            >
              Add user
            </Button>
          </Col>
          <Col md={6} className="text-right">
            <Button
              onClick={save}
              color="primary"
              disabled={!dirty || disableSaveBtn}
              className="control-buttons"
            >
              Save
            </Button>
            <Button onClick={cancel} color="primary" disabled={!dirty}>
              Cancel
            </Button>
          </Col>
        </Row>
        {newUser || (userEmail && user) ? (
          <Form>
            <FormGroup row>
              <Label for="Name" md={3}>
                Name:
              </Label>
              <Col md={5}>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  onChange={onChange(setName)}
                  value={name}
                  placeholder="Name"
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="Email" md={3}>
                Email:
              </Label>
              <Col md={5}>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  onChange={onChange(setEmail)}
                  value={email}
                  placeholder="Email"
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="admin" md={3}>
                Administrator:
              </Label>
              <Col md={5}>
                <Input
                  type="checkbox"
                  name="admin"
                  id="admin"
                  onChange={onChangeCheckbox}
                  checked={admin}
                />
                <h6 className="administrator-message" for="admin">
                  Administrators can manage users
                </h6>
              </Col>
            </FormGroup>
            <Button onClick={del} color="danger" disabled={!user}>
              Delete user
            </Button>
          </Form>
        ) : (
          <h4>Select a user...</h4>
        )}
      </Col>
      <ModalConfirm
        modalFlag={confirmModal}
        body="Are you sure you want to delete this user?"
        confirmButtonLabel="Yes"
        className=""
        confirmCallback={confirmUserDelete}
        cancelCallback={cancelUserDelete}
        title="Confirm delete user"
      />
    </Row>
  );
};

export default Users;
