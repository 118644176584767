import * as constants from "../utility/constants";
import { customAlphabet } from "nanoid";
const nanoid = customAlphabet("ABCDEFGHIJKLMNOPQRSTUVWXYZ", constants.KEY_SIZE);

const initTransaction = (parms) => {
  let key = nanoid();
  return {
    key,
    transactionId: key,
    date: parms.date,
    ticketNumber: parms.ticketNumber,

    studentId: parms.studentId,
    firstName: parms.firstName,
    lastName: parms.lastName,
    guestFirstName: parms.guestFirstName,
    guestLastName: parms.guestLastName,

    isGuest: parms.isGuest,
    isStudent: parms.isStudent,

    paymentType: parms.paymentType,
    price: parms.price,
    soldByName: parms.soldByName,
    soldByEmail: parms.soldByEmail,
    cancelledBy: parms.cancelledBy,
    notes: parms.notes,

    cancelDate: "",
    refundDate: "",
  };
};

export default initTransaction;
