import React from "react";
import { Card, CardHeader, CardFooter, CardBody, Row, Col } from "reactstrap";
import "./Ticket.css";
import constants from "../../utility/constants";
import TicketStatus from "./TicketStatus";
import { useSelector } from "react-redux";
import getUnixTime from "date-fns/getUnixTime";

const Ticket = ({ student, lop, event }) => {
  const firebaseDefault = useSelector((state) => state.firebaseDefault);
  const fbUser = useSelector((state) => state.fbUser);
  const db = firebaseDefault.firestore();
  const eventRef = db.collection(constants.EVENT_LIST_KEY).doc(event.key);

  const cancelClick = (isGuest) => {
    if (!isGuest) {
      processCancel(false);
    }
    processCancel(true);
  };

  const processCancel = async (isGuest) => {
    const cancelledTransactionId = isGuest
      ? student.guestTransaction
      : student.studentTransaction;
    if (cancelledTransactionId.length > 0) {
      const transaction = await getTransaction(cancelledTransactionId);
      transaction.cancelDate = getUnixTime(new Date());
      transaction.cancelledBy = fbUser.displayName;
      if (isGuest) {
        student.guestTransaction = "";
      } else {
        student.studentTransaction = "";
      }
      const batch = db.batch();
      batch.set(
        eventRef
          .collection(constants.TRANSACTION_LIST_KEY)
          .doc(transaction.key),
        transaction
      );
      batch.set(
        eventRef.collection(constants.STUDENT_LIST_KEY).doc(student.id),
        student
      );
      batch.commit();
    }
  };

  const getTransaction = async (transactionId) => {
    const doc = await eventRef
      .collection(constants.TRANSACTION_LIST_KEY)
      .doc(transactionId)
      .get();
    return doc.data();
  };

  return (
    <Card className="ticket">
      <CardHeader className={lop?.onLOP ? "student-on-lop" : ""}>
        <div>
          {student.firstName} {student.lastName}{" "}
          {!student.active ? " (Inactive)" : ""}
        </div>
        <div className="student-info">
          (ID: {student.id} | Gr: {student.grade} | C/O: {student.classOf})
        </div>
        <div className="lop-status student-info">
          {lop?.onLOP ? (
            <span>
            {
              // `AcGPA: ${lop.acGPA} | CitGPA: ${lop.citGPA} | LOP recs: ${lop.lopRecords} | Att: A:${lop.A} T:${lop.T} Z:${lop.Z}`
              `AcGPA: ${lop.acGPA} | CitGPA: ${lop.citGPA} | LOP recs: ${lop.lopRecords} | Att recs: ${lop.attCount}`
            }
            </span>
          ) : null}
        </div>
      </CardHeader>
      <CardBody>
        <Row>
          <Col md={6}>
            <TicketStatus
              student={student}
              isGuest={false}
              event={event}
              cancelClick={cancelClick}
              allowGuestToPurchase={student.studentTransaction.length > 0}
            />
          </Col>
          <Col md={6}>
            <TicketStatus
              student={student}
              isGuest={true}
              event={event}
              cancelClick={cancelClick}
              allowGuestToPurchase={student.studentTransaction.length > 0}
            />
          </Col>
        </Row>
      </CardBody>
      {lop?.onLOP ? (
        <CardFooter>
          <span className="lop-detail">
            {
              // `AcGPA: ${lop.acGPA} | CitGPA: ${lop.citGPA} | LOP recs: ${lop.lopRecords} | Att: A:${lop.A} T:${lop.T} Z:${lop.Z}`
              `AcGPA: ${lop.acGPA} | CitGPA: ${lop.citGPA} | LOP recs: ${lop.lopRecords} | Att recs: ${lop.attCount}`
            }
          </span>
        </CardFooter>
      ) : null}
    </Card>
  );
};

export default Ticket;
