import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Table,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import Papa from "papaparse";
import { produce } from "immer";
import "firebase/firestore";
import "../Event/Event.css";
import getRandomString from "../../utility/getRandomString";
import "./StudentImport.css";
import firebase from "firebase/app";
import constants from "../../utility/constants";
import initStudent from "../../models/initStudent";
const partition = require("ramda/src/partition");

const StudentImport = () => {
  const LIMIT = 5;
  const [csvData, setCsvData] = useState(null);
  const [badCsvData, setBadCsvData] = useState(null);
  const [skipFirst, setSkipFirst] = useState(false);
  const [importing, setImporting] = useState(false);
  const [importSuccessful, setImportSuccessful] = useState(false);
  const [importFailed, setImportFailed] = useState(false);
  const [fileInputString, setFileInputString] = useState(getRandomString(10));
  const fieldHeader = {
    password: "Last name, First name, Criteria, ID (or email), Password",
    google: "Last name, First name, Criteria, Email",
  };

  const eventList = useSelector((state) => state.eventList);
  const eventKey = useSelector((state) => state.eventKey);
  const event =
    eventList && eventKey ? eventList.find((el) => el.key === eventKey) : null;
  const studentList = useSelector((state) => state.studentList);

  const db = firebase.firestore();

  const onChange = (evt) => {
    let reader = new FileReader();
    reader.onload = function () {
      let csvText = reader.result;
      let csvData = Papa.parse(csvText);
      csvData.data = csvData.data.filter((rec) => rec.join("").length > 0);
      const [badCsv, goodCsv] = partition(
        (rec) =>
          rec[0].length === 0 ||
          rec[1].length === 0 ||
          rec[2].length === 0 ||
          rec[3].length === 0 ||
          rec[4].length === 0 ||
          rec[5].length === 0,
        csvData.data
      );
      setCsvData(goodCsv);
      setBadCsvData(badCsv);
    };
    reader.readAsText(evt.target.files[0]);
  };

  const onChangeSkipFirst = (evt) => {
    setSkipFirst(evt.currentTarget.checked);
  };

  const onImportClick = () => {
    let _studentList = JSON.parse(JSON.stringify(studentList));
    setImporting(true);
    setImportFailed(false);
    setImportSuccessful(false);
    let _csvData = produce(csvData, (draft) => {
      if (skipFirst) {
        draft.slice(1);
      }
    });
    _csvData = _csvData.map((rec) => {
      if (rec.length < (skipFirst ? 2 : 1)) return null;
      return initStudent(rec);
    });
    if (skipFirst) _csvData.shift();
    const studentListObj = _studentList.reduce((slo, s) => {
      slo[s.id] = s;
      slo[s.id].active = false;
      return slo;
    }, {});
    const mergedListObj = _csvData.reduce((mlo, s) => {
      if (mlo[s.id]) {
        mlo[s.id] = { ...mlo[s.id], ...s };
        mlo[s.id].active = true;
      } else {
        mlo[s.id] = s;
        mlo[s.id].active = true;
      }
      return mlo;
    }, studentListObj);
    const newStudentList = Object.values(mergedListObj);
    if (newStudentList.length === 0) return;
    const batches = [db.batch()];
    let count = 0;
    let idx = 0;
    let MAX = 400;
    newStudentList.forEach((s) => {
      const dbRef = db
        .collection(constants.EVENT_LIST_KEY)
        .doc(eventKey)
        .collection(constants.STUDENT_LIST_KEY)
        .doc(s.key);
      batches[idx].set(dbRef, s);
      count++;
      if (count > MAX) {
        idx++;
        count = 0;
        batches[idx] = db.batch();
      }
    });
    batches.forEach((b) => b.commit());
    Promise.all(batches)
      .then((val) => {
        setFileInputString(getRandomString(10));
        setImporting(false);
        setImportSuccessful(true);
      })
      .catch((err) => {
        setFileInputString(getRandomString(10));
        setImporting(false);
        setImportFailed(true);
      });
  };

  const _csvData = csvData
    ? skipFirst
      ? csvData.slice(1, 6)
      : csvData.slice(0, 5)
    : [];

  return (
    <Row>
      <Col md={9}>
        <Card>
          <CardHeader>
            {event ? (
              <h5>{studentList?.length ?? "--"} students are eligible</h5>
            ) : null}
          </CardHeader>
          <CardBody>
            <Table size="sm">
              <thead>
                <tr>
                  <th className="text-center" colSpan={4}>
                    First {LIMIT} existing student records. Passwords are not
                    shown for privacy.
                  </th>
                </tr>
                <tr>
                  <th>ID</th>
                  <th>Last name</th>
                  <th>First name</th>
                  <th className="text-center">Grade</th>
                  <th className="text-center">C/O</th>
                </tr>
              </thead>
              <tbody>
                {studentList.map((student, idx) => {
                  if (idx >= LIMIT) return null;
                  return (
                    <tr key={student.key}>
                      <td>{student.id}</td>
                      <td>{student.lastName}</td>
                      <td>{student.firstName}</td>
                      <td className="text-center">{student.grade}</td>
                      <td className="text-center">{student.classOf}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <hr />
            <CardTitle>
              <Form>
                <FormGroup row>
                  <Col md={12}>
                    <h6>
                      <span className="import-fields">Data fields: </span>
                      {fieldHeader[event.authType]}
                    </h6>
                    <Label for="restoreFile">Skip first record:</Label>
                    <Input
                      type="checkbox"
                      id="skipFirst"
                      name="skipFirst"
                      onChange={onChangeSkipFirst}
                      checked={skipFirst}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md={4}>
                    <Label for="restoreFile">Choose student import file</Label>
                    <Input
                      id="studentFile"
                      name="studentFile"
                      accept="text/csv,text/text,.csv,.text,.txt"
                      type="file"
                      key={fileInputString}
                      onChange={onChange}
                    />
                  </Col>
                  <Col md={2}>
                    <Button
                      block
                      onClick={onImportClick}
                      disabled={!csvData || importing}
                      color="primary"
                    >
                      {importing ? "Importing..." : "Import"}
                    </Button>
                  </Col>
                  <Col md={6}>
                    {importSuccessful ? (
                      <h5>Student import was successful!</h5>
                    ) : null}
                    {importFailed ? (
                      <h5 className="import-failed">Student import failed</h5>
                    ) : null}
                  </Col>
                </FormGroup>
              </Form>
            </CardTitle>
            {csvData ? (
              <div>
                <div className="import-data-list-header">
                  The first five records of import file are below. Check for the
                  proper data field location.
                </div>
                <Table size="sm">
                  <thead>
                    <tr>
                      <th className="text-center">ID</th>
                      <th>Last name</th>
                      <th>First name</th>
                      <th className="text-center">Grade</th>
                      <th className="text-center">C/O</th>
                      <th className="text-center">Password</th>
                    </tr>
                  </thead>
                  <tbody>
                    {_csvData.map((rec) => (
                      <tr key={rec[0]}>
                        <td className="text-center">{rec[0]}</td>
                        <td>{rec[1]}</td>
                        <td>{rec[2]}</td>
                        <td className="text-center">{rec[3]}</td>
                        <td className="text-center">{rec[4]}</td>
                        <td className="text-center">{rec[5]}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            ) : null}
            {badCsvData ? (
              <div className="bad-import-data">
                <div className="import-data-list-header">
                  These students have one or more blank input fields and cannot
                  be imported. You can add these students manually from the Add
                  Students page.
                </div>
                <Table size="sm">
                  <thead>
                    <tr>
                      <th className="text-center">ID</th>
                      <th>Last name</th>
                      <th>First name</th>
                      <th className="text-center">Grade</th>
                      <th className="text-center">C/O</th>
                      <th className="text-center">Password</th>
                    </tr>
                  </thead>
                  <tbody>
                    {badCsvData.map((rec) => (
                      <tr key={rec[0]}>
                        <td className="text-center">{rec[0]}</td>
                        <td>{rec[1]}</td>
                        <td>{rec[2]}</td>
                        <td className="text-center">{rec[3]}</td>
                        <td className="text-center">{rec[4]}</td>
                        <td className="text-center">{rec[5]}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            ) : null}
          </CardBody>
          <CardFooter>
            <h6 className="import-warning">
              NOTE: New student information will be merged with the existing
              students. Event information will be preserved.
            </h6>
          </CardFooter>
        </Card>
      </Col>
      <Col md={3}>
        <Card>
          <CardHeader>
            <h5>CSV field explanation</h5>
          </CardHeader>
          <CardBody>
            <h6>
              <span className="bold-label">ID number</span>: Student ID number.
              ID numbers must be unique for each student.{" "}
              <span className="ps-field">(PS: Student_Number)</span>
            </h6>
            <h6>
              <span className="bold-label">Last name</span>: The student's last
              name <span className="ps-field">(PS: Last_Name)</span>
            </h6>
            <h6>
              <span className="bold-label">First name</span>: The student's
              first name <span className="ps-field">(PS: First_Name)</span>
            </h6>
            <h6>
              <span className="bold-label">Grade</span>: Student's grade level
              <span className="ps-field">(PS: Grade_Level)</span>
            </h6>
            <h6>
              <span className="bold-label">Class of</span>: Student's graduating
              class year{" "}
              <span className="ps-field">(PS: ClassOf)</span>
            </h6>
            <h6>
              <span className="bold-label">Password</span>: Student's district
              password{" "}
              <span className="ps-field">(PS: U_Students_AD.AD_passwd)</span>
            </h6>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default StudentImport;
