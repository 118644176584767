const initialState = (firebaseDefault) => {
  return {
    firebaseDefault,
    fbUser: null,
    fbAdditionalUserInfo: null,
    fsUser: null,
    isLoggedIn: false,
    errorCode: null,
    unauthorized: false,

    claims: null,
    idToken: null,

    eventKey: null,
    eventList: null,
    studentList: null,
    transactionList: null,
  };
};

export default initialState;
