import React from "react";
import { Row, Col, CardBody } from "reactstrap";
import "./AuditSummary.css";

const AuditSummary = ({ transactionList }) => {
  if (!transactionList) return null;

  let summary = {
    tickets: { students: 0, guests: 0, total: 0 },
    cancelled: { students: 0, guests: 0, total: 0 },
    cancelledNoRefund: { students: 0, guests: 0, total: 0 },
    sales: { students: 0, guests: 0, total: 0 },
    refunds: { students: 0, guests: 0, total: 0 },
    grossSales: 0,
    totalRefunds: 0,
    netSales: 0,
  };

  summary = transactionList.reduce((summary, t) => {
    summary.tickets.students += t.isStudent && t.cancelDate === "" ? 1 : 0;
    summary.tickets.guests += t.isGuest && t.cancelDate === "" ? 1 : 0;
    summary.tickets.total += t.cancelDate === "" ? 1 : 0;

    summary.cancelled.students += t.isStudent && t.cancelDate !== "" ? 1 : 0;
    summary.cancelled.guests += t.isGuest && t.cancelDate !== "" ? 1 : 0;
    summary.cancelled.total += t.cancelDate !== "" ? 1 : 0;

    summary.cancelledNoRefund.students +=
      t.isStudent && t.cancelDate !== "" && t.refundDate === "" ? 1 : 0;
    summary.cancelledNoRefund.guests +=
      t.isGuest && t.cancelDate !== "" && t.refundDate === "" ? 1 : 0;
    summary.cancelledNoRefund.total +=
      t.cancelDate !== "" && t.refundDate === "" ? 1 : 0;

    summary.sales.students += t.isStudent ? parseFloat(t.price) : 0;
    summary.sales.guests += t.isGuest ? parseFloat(t.price) : 0;
    summary.sales.total += parseFloat(t.price);

    summary.refunds.students +=
      t.isStudent && t.refundDate !== "" ? parseFloat(t.price) : 0;
    summary.refunds.guests +=
      t.isGuest && t.refundDate !== "" ? parseFloat(t.price) : 0;
    summary.refunds.total += t.refundDate !== "" ? parseFloat(t.price) : 0;

    summary.grossSales += parseFloat(t.price);
    summary.totalRefunds += t.cancelDate !== "" ? parseFloat(t.price) : 0;
    return summary;
  }, summary);
  summary.netSales = summary.grossSales - summary.totalRefunds;
  console.log(summary);

  return (
    <CardBody>
      <Row>
        <Col md={{ size: 4, offset: 5 }}>
          <table>
            <tbody>
              <tr>
                <td className="summary-heading" colSpan={2}>
                  <span>Students:</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span className="indent">Individual:</span>
                </td>
                <td>
                  <span>{summary.tickets.students}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span className="indent">With guests:</span>
                </td>
                <td>
                  <span>{summary.tickets.guests}</span>
                </td>
              </tr>
              <tr className="total">
                <td>
                  <span className="indent">Total:</span>
                </td>
                <td>
                  <span>{summary.tickets.total}</span>
                </td>
              </tr>
              <tr>
                <td className="summary-heading" colSpan={2}>
                  <span>Cancellations:</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span className="indent">Students:</span>
                </td>
                <td>
                  <span>{summary.cancelled.students}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span className="indent">Guests:</span>
                </td>
                <td>
                  <span>{summary.cancelled.guests}</span>
                </td>
              </tr>
              <tr className="total">
                <td>
                  <span className="indent">Total:</span>
                </td>
                <td>
                  <span>{summary.cancelled.total}</span>
                </td>
              </tr>
              <tr>
                <td className="summary-heading" colSpan={2}>
                  <span>Cancelled no refund processed:</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span className="indent">Students:</span>
                </td>
                <td>
                  <span>{summary.cancelledNoRefund.students}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span className="indent">Guests:</span>
                </td>
                <td>
                  <span>{summary.cancelledNoRefund.guests}</span>
                </td>
              </tr>
              <tr className="total">
                <td>
                  <span className="indent">Total:</span>
                </td>
                <td>
                  <span>{summary.cancelledNoRefund.total}</span>
                </td>
              </tr>
              <tr>
                <td className="summary-heading" colSpan={2}>
                  <span>Total sales:</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span className="indent">Students:</span>
                </td>
                <td>
                  <span>$ {summary.sales.students.toFixed(2)}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span className="indent">Guests:</span>
                </td>
                <td>
                  <span>$ {summary.sales.guests.toFixed(2)}</span>
                </td>
              </tr>
              <tr className="total">
                <td>
                  <span className="indent">Total:</span>
                </td>
                <td>
                  <span>$ {summary.sales.total.toFixed(2)}</span>
                </td>
              </tr>
              <tr>
                <td className="summary-heading" colSpan={2}>
                  <span>Total refunds:</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span className="indent">Students:</span>
                </td>
                <td>
                  <span>$ {summary.refunds.students.toFixed(2)}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span className="indent">Guests:</span>
                </td>
                <td>
                  <span>$ {summary.refunds.guests.toFixed(2)}</span>
                </td>
              </tr>
              <tr className="total">
                <td>
                  <span className="indent">Total:</span>
                </td>
                <td>
                  <span>$ {summary.refunds.total.toFixed(2)}</span>
                </td>
              </tr>
              <tr>
                <td className="summary-heading" colSpan={2}>
                  <span>Summary:</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span className="indent">Gross sales:</span>
                </td>
                <td>
                  <span>$ {summary.grossSales.toFixed(2)}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span className="indent">Less refunds:</span>
                </td>
                <td>
                  <span>$ {summary.totalRefunds.toFixed(2)}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span className="indent">Net sales:</span>
                </td>
                <td>
                  <span>$ {summary.netSales.toFixed(2)}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </Col>
      </Row>
    </CardBody>
  );
};

export default AuditSummary;
