import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Button, Card, CardHeader } from "reactstrap";
import { download } from "../../utility/download";
import { format, fromUnixTime } from "date-fns";
import "./Audit.css";
import AuditTransactions from "./AuditTransactions";
import AuditSummary from "./AuditSummary";

const Audit = () => {
  const [event, setEvent] = useState(null);
  const [showSummary, setShowSummary] = useState(false);

  const transactionList = useSelector((state) => state.transactionList ?? []);
  const eventList = useSelector((state) => state.eventList);
  const eventKey = useSelector((state) => state.eventKey);

  useEffect(() => {
    if (!eventList || !eventKey) return;
    setEvent(eventList.find((e) => e.key === eventKey));
  }, [eventKey, eventList]);

  const sortFcn = (a, b) => {
    if (a.cancelDate === "" && b.cancelDate === "") {
      return a.date - b.date;
    } else if (a.cancelDate === "" && b.cancelDate > 0) {
      return -1;
    } else if (a.cancelDate > 0 && b.cancelDate === "") {
      return 1;
    } else {
      return a.cancelDate - b.cancelDate;
    }
  };

  let _transactionList = JSON.parse(JSON.stringify(transactionList));
  _transactionList = _transactionList.sort(sortFcn);

  const downloadData = () => {
    const csv =
      "Student ID,Last name,First name,Guest name,Ticket number,Transaction ID,Purchase date,Payment type,Price,Sold by,Cancel date,Refund date,Notes\r\n" +
      _transactionList
        .map((t) => {
          const arr = [
            t.studentId,
            t.lastName,
            t.firstName,
            t.guestFirstName + " " + t.guestLastName,
            t.ticketNumber,
            t.transactionId,
            t.date === ""
              ? ""
              : format(fromUnixTime(t.date), "MMM d yyyy hh:mm a"),
            t.paymentType,
            t.price,
            t.soldByName,
            t.cancelDate === ""
              ? ""
              : format(fromUnixTime(t.cancelDate), "MMM d yyyy hh:mm a"),
            t.refundDate === ""
              ? ""
              : format(fromUnixTime(t.refundDate), "MMM d yyyy hh:mm a"),
            t.notes
          ];
          return arr.join(",");
        })
        .join("\r\n");
    download(csv, "EventRoster.csv", "text/csv");
  };

  const toggleCancelled = (evt) => {
    setShowSummary((c) => !c);
  };

  if (!event)
    return (
      <Card>
        <CardHeader>
          <Row>
            <Col md={2} className="roster-control-buttons">
              &nbsp;
            </Col>
            <Col md={8}>
              <h6>Loading transactions...</h6>
            </Col>
          </Row>
        </CardHeader>
      </Card>
    );

  return (
    <>
      <Card>
        <CardHeader>
          <Row>
            <Col md={2}>
              <Button color="primary" onClick={toggleCancelled}>
                {showSummary ? "Show transactions" : "Show summary"}
              </Button>
            </Col>
            <Col md={8}>
              <h4>{event?.name}</h4>
              <h6>{showSummary ? "Summary" : "Transaction List"}</h6>
            </Col>
            <Col md={2} className="text-right">
              {!showSummary ? (
                <Button color="primary" onClick={downloadData}>
                  Download
                </Button>
              ) : null}
            </Col>
          </Row>
        </CardHeader>
        {showSummary ? (
          <AuditSummary transactionList={_transactionList} />
        ) : (
          <AuditTransactions event={event} transactionList={_transactionList} />
        )}
      </Card>
    </>
  );
};

export default Audit;
