import React from "react";
import { CardBody, Button } from "reactstrap";
import refundComplete from "../../utility/refundComplete";
import { format, fromUnixTime } from "date-fns";
import { useSelector } from "react-redux";

const AuditTransactions = ({ transactionList, event }) => {
  const firebaseDefault = useSelector((state) => state.firebaseDefault);

  const pendingClick = (evt) => {
    const transactionId = evt.currentTarget.getAttribute("data-id");
    const transaction = transactionList.find((t) => transactionId === t.key);
    if (!transaction) return;
    refundComplete(firebaseDefault, event, transaction);
  };

  if (!transactionList) return null;

  return (
    <CardBody>
      <table className="audit-table">
        <thead>
          <tr>
            <th>
              <span>Student ID</span>
            </th>
            <th>
              <span>Last name</span>
            </th>
            <th>
              <span>First name</span>
            </th>
            <th>
              <span>Guest name</span>
            </th>
            <th className="text-center">
              <span>Ticket number</span>
            </th>
            <th className="text-center">
              <span>Transaction ID</span>
            </th>
            <th className="text-center">
              <span>Purchase date</span>
            </th>
            <th className="text-center">
              <span>Payment type</span>
            </th>
            <th className="text-center">
              <span>Price</span>
            </th>
            <th className="text-center">
              <span>Sold by</span>
            </th>
            <th className="text-center">
              <span>Cancel date</span>
            </th>
            <th className="text-center">
              <span>Refund date</span>
            </th>
            <th className="text-center">
              <span>Cancelled by</span>
            </th>
            <th className="text-center">
              <span>Notes</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {transactionList.map((t) => (
            <tr key={t.key}>
              <td className="text-center">
                <span>{t.studentId}</span>
              </td>
              <td>
                <span>{t.lastName}</span>
              </td>
              <td>
                <span>{t.firstName}</span>
              </td>
              <td>
                <span>{t.guestFirstName + " " + t.guestLastName}</span>
              </td>
              <td className="text-center">
                <span>{t.ticketNumber}</span>
              </td>
              <td className="text-center">
                <span>{t.transactionId}</span>
              </td>
              <td className="text-center">
                <span>
                  {format(fromUnixTime(t.date), "MM/dd/yyyy hh:mm a")}
                </span>
              </td>
              <td className="text-center">
                <span>{t.paymentType}</span>
              </td>
              <td className="text-center">
                <span>{t.price}</span>
              </td>
              <td className="text-center">
                <span>{t.soldByName}</span>
              </td>
              <td className="text-center">
                <span>
                  {t.cancelDate === ""
                    ? ""
                    : format(fromUnixTime(t.cancelDate), "MM/dd/yyyy hh:mm a")}
                </span>
              </td>
              <td className="text-center">
                {t.refundDate === "" && t.cancelDate !== "" ? (
                  <Button
                    size="sm"
                    color="danger"
                    data-id={t.transactionId}
                    className="pending-button"
                    onClick={pendingClick}
                  >
                    Pending
                  </Button>
                ) : (
                  <span>
                    {t.refundDate === ""
                      ? ""
                      : format(
                          fromUnixTime(t.refundDate),
                          "MM/dd/yyyy hh:mm a"
                        )}
                  </span>
                )}
              </td>
              <td className="text-center">
                <span>{t.cancelledBy ?? ""}</span>
              </td>
              <td className="text-center">
                <span>{t.notes}</span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </CardBody>
  );
};

export default AuditTransactions;
