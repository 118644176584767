import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from "reactstrap";
import React, { useEffect, useState } from "react";
import format from "date-fns/format";
import getUnixTime from "date-fns/getUnixTime";
import fromUnixTime from "date-fns/fromUnixTime";
import { useSelector } from "react-redux";
import initTransaction from "../../models/initTransaction";
import constants from "../../utility/constants";
const NONE = "NONE";

const TicketStatus = ({
  student,
  event,
  isGuest,
  allowGuestToPurchase,
  cancelClick,
}) => {
  const [ticket, setTicket] = useState("");
  const [lastName, setLastName] = useState(
    isGuest ? student.guestLastName ?? "" : student.lastName ?? ""
  );
  const [firstName, setFirstName] = useState(
    isGuest ? student.guestFirstName ?? "" : student.firstName ?? ""
  );
  const [priceChoice, setPriceChoice] = useState(NONE);
  const [price, setPrice] = useState(
    isGuest ? student.guestPrice ?? "" : student.studentPrice ?? ""
  );
  const [paymentType, setPaymentType] = useState(
    isGuest ? student.guestPaymentType ?? "" : student.studentPaymentType ?? ""
  );
  const [hasTicket, setHasTicket] = useState(false);
  const [transaction, setTransaction] = useState(null);
  const [notes, setNotes] = useState("");

  const [processing, setProcessing] = useState(false);
  const [disablePurchaseButton, setDisablePurchaseButton] = useState(false);

  const eventKey = useSelector((state) => state.eventKey);
  const fbUser = useSelector((state) => state.fbUser);
  const firebaseDefault = useSelector((state) => state.firebaseDefault);
  const studentJSON = JSON.stringify(student);

  const onChange = (setter) => (evt) => {
    setter(evt.currentTarget.value);
  };

  useEffect(() => {
    setProcessing(false);
  }, [studentJSON]);

  useEffect(() => {
    const guestHasTicket = isGuest && student.guestTransaction.length > 0;
    const studentHasTicket = !isGuest && student.studentTransaction.length > 0;
    setHasTicket(guestHasTicket || studentHasTicket);
  }, [
    isGuest,
    student.guestTransaction.length,
    student.studentTransaction.length,
  ]);

  useEffect(() => {
    if (!student) return;
    if (isGuest) {
      setLastName("");
      setFirstName("");
    } else {
      setLastName(student.lastName);
      setFirstName(student.firstName);
    }
  }, [student, student.lastName, student.firstName, isGuest]);

  useEffect(() => {
    if (isGuest && student.guestTransaction) {
      setLastName("");
      setFirstName("");
      setTicket("");
      setPaymentType("");
      setPrice("");
      setNotes("");
    }
    if (!isGuest && student.studentTransaction === "") {
      setTicket("");
      setPaymentType("");
      setPrice("");
      setNotes("");
      const els = document.getElementsByName("pricetype");
      els.forEach((el) => (el.checked = false));
    }
  }, [isGuest, student.guestTransaction, student.studentTransaction]);

  useEffect(() => {
    if (!transaction) return;
    setTicket(transaction.ticketNumber);
    setPaymentType(transaction.paymentType);
    setPrice(transaction.price);
    setNotes(transaction.notes);
  }, [transaction]);

  useEffect(() => {
    setDisablePurchaseButton(
      paymentType?.length === 0 ||
        price?.length === 0 ||
        lastName?.length === 0 ||
        ticket?.length === 0 ||
        firstName?.length === 0
    );
  }, [paymentType, price, lastName, firstName, ticket]);

  useEffect(() => {
    const transactionId = isGuest
      ? student.guestTransaction
      : student.studentTransaction;
    if (transactionId.length === 0) {
      setTransaction(null);
    } else {
      firebaseDefault
        .firestore()
        .collection(constants.EVENT_LIST_KEY)
        .doc(eventKey)
        .collection(constants.TRANSACTION_LIST_KEY)
        .doc(transactionId)
        .get()
        .then((doc) => {
          setTransaction(doc.data());
        });
    }
  }, [
    student.studentTransaction,
    student.guestTransaction,
    isGuest,
    firebaseDefault,
    eventKey,
  ]);

  const onPurchaseClick = () => {
    setProcessing(true);
    const t1 = initTransaction({
      date: getUnixTime(new Date()),
      ticketNumber: ticket,
      studentId: student.id,
      firstName: student.firstName,
      lastName: student.lastName,
      guestFirstName: isGuest ? firstName : "",
      guestLastName: isGuest ? lastName : "",

      isGuest,
      isStudent: !isGuest,

      paymentType: paymentType,
      price: price,
      soldByName: fbUser.displayName,
      soldByEmail: fbUser.email,
      cancelledBy: "",
      notes,
    });
    if (isGuest) {
      student.guestTransaction = t1.key;
    } else {
      student.studentTransaction = t1.key;
    }
    const db = firebaseDefault.firestore();
    const eventRef = db.collection(constants.EVENT_LIST_KEY).doc(eventKey);
    // const queueRef = eventRef.collection(constants.QUEUE_LIST_KEY);
    const studentRef = eventRef
      .collection(constants.STUDENT_LIST_KEY)
      .doc(student.id);
    const t1Ref = eventRef
      .collection(constants.TRANSACTION_LIST_KEY)
      .doc(t1.key);
    const batch = db.batch();
    batch.set(studentRef, student);
    // batch.set(queueRef.doc(t1.key), { status: "WAITING" });
    batch.set(t1Ref, t1);
    batch
      .commit()
      .then(() => {
        setProcessing(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onCancelClick = () => {
    setProcessing(true);
    cancelClick(isGuest);
  };

  const selectPriceChoice = (choiceSetter, priceSetter) => (evt) => {
    const choice = evt.currentTarget.getAttribute("id");
    choiceSetter(choice);
    switch (choice) {
      case "other":
        priceSetter("");
        break;
      case "asb":
        priceSetter(event?.priceWithASB);
        break;
      case "noasb":
        priceSetter(event?.priceWithoutASB);
        break;
      default:
        break;
    }
  };

  return (
    <Card>
      <CardHeader className={transaction ? "ticketed" : "not-ticketed"}>
        <h5>{isGuest ? "Guest " : "Student "}Ticket</h5>
      </CardHeader>
      <CardBody>
        <Form>
          <FormGroup row tag="fieldset">
            <Label for="lastName" md={6}>
              {isGuest ? "Guest last name:" : "Student last name:"}
            </Label>
            <Col md={6}>
              <Input
                type="text"
                name="lastName"
                id="lastName"
                onChange={onChange(setLastName)}
                value={lastName}
                disabled={!isGuest || hasTicket}
                placeholder="Guest last name"
              />
            </Col>
          </FormGroup>
          <FormGroup row tag="fieldset">
            <Label for="firstName" md={6}>
              {isGuest ? "Guest first name:" : "Student first name:"}
            </Label>
            <Col md={6}>
              <Input
                type="text"
                name="firstName"
                id="firstName"
                onChange={onChange(setFirstName)}
                value={firstName}
                disabled={!isGuest || hasTicket}
                placeholder="Guest first name"
              />
            </Col>
          </FormGroup>
          <FormGroup row tag="fieldset">
            <Label for="ticket" md={6}>
              Ticket number:
            </Label>
            <Col md={6}>
              <Input
                type="text"
                name="ticket"
                id="ticket"
                onChange={onChange(setTicket)}
                value={ticket}
                disabled={hasTicket}
                placeholder="Ticket #"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="paymentType" md={6}>
              Payment type:
            </Label>
            <Col md={6}>
              <Input
                id="paymentType"
                name="paymentType"
                type="select"
                value={paymentType}
                disabled={hasTicket}
                onChange={onChange(setPaymentType)}
              >
                <option value="">Select...</option>
                <option value="Check">Check</option>
                <option value="Cash">Cash</option>
                <option value="Credit">Credit card</option>
                <option value="Other">Other</option>
              </Input>
            </Col>
          </FormGroup>
          <FormGroup row tag="fieldset">
            <Label md={6}>Price:</Label>
            <Col md={6}>
              <Input
                type="number"
                name="price"
                id="price"
                data-page="event"
                onChange={onChange(setPrice)}
                value={price}
                disabled={hasTicket}
                placeholder={
                  priceChoice === NONE ? "Select option" : "Enter price"
                }
              />
            </Col>
          </FormGroup>
          <FormGroup row className="ticket-line-spacing-riser">
            <Col md={6} className="ticket-price-radios">
              <FormGroup check>
                <Input
                  name="pricetype"
                  type="radio"
                  id="asb"
                  disabled={hasTicket}
                  onClick={selectPriceChoice(setPriceChoice, setPrice)}
                />{" "}
                <Label check className="non-bold">
                  w/ASB card
                </Label>
              </FormGroup>
              <FormGroup check>
                <Input
                  name="pricetype"
                  type="radio"
                  id="noasb"
                  disabled={hasTicket}
                  onClick={selectPriceChoice(setPriceChoice, setPrice)}
                />{" "}
                <Label check className="non-bold">
                  w/o ASB card
                </Label>
              </FormGroup>
              <FormGroup check>
                <Input
                  name="pricetype"
                  type="radio"
                  id="other"
                  disabled={hasTicket}
                  onClick={selectPriceChoice(setPriceChoice, setPrice)}
                />
                <Label check className="non-bold">
                  Other
                </Label>
              </FormGroup>
            </Col>
          </FormGroup>
          <FormGroup row tag="fieldset">
            <Label md={6}>Notes:</Label>
            <Col md={6}>
              <Input
                type="textarea"
                name="notes"
                id="notes"
                data-page="event"
                onChange={onChange(setNotes)}
                value={notes}
                disabled={hasTicket}
              />
            </Col>
          </FormGroup>
          {hasTicket ? (
            <>
              <FormGroup row className="ticket-line-spacing">
                <Label md={6}>Date:</Label>
                <Col md={6}>
                  <h6 className="ticket-display-label">
                    {transaction
                      ? format(fromUnixTime(transaction?.date), "MMM d, yyyy")
                      : ""}
                  </h6>
                </Col>
              </FormGroup>
              <FormGroup row className="ticket-line-spacing">
                <Label md={6}>Time:</Label>
                <Col md={6}>
                  <h6 className="ticket-display-label">
                    {transaction
                      ? format(fromUnixTime(transaction?.date), "h:mm:ss a")
                      : ""}
                  </h6>
                </Col>
              </FormGroup>
              <FormGroup row className="ticket-line-spacing">
                <Label md={6}>Sold by:</Label>
                <Col md={6}>
                  <h6 className="ticket-display-label">
                    {transaction?.soldByName}
                  </h6>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md={6}>Transaction ID:</Label>
                <Col md={6}>
                  <h6 className="ticket-display-label">
                    {transaction?.transactionId}
                  </h6>
                </Col>
              </FormGroup>
            </>
          ) : null}
        </Form>
      </CardBody>
      <CardFooter>
        <Row>
          <Col md={6}>
            {hasTicket ? (
              <Button block color="danger" onClick={onCancelClick}>
                {processing ? "Processing" : "Cancel"}
              </Button>
            ) : (
              <Button
                block
                color="primary"
                disabled={
                  disablePurchaseButton || (isGuest && !allowGuestToPurchase)
                }
                onClick={onPurchaseClick}
              >
                {processing ? "Processing" : "Purchase"}
              </Button>
            )}
          </Col>
          <Col md={6}>
            {!isGuest && student.guestTransaction.length > 0 ? (
              <div className="refund-notice">
                Canceling will also cancel the guest
              </div>
            ) : null}
          </Col>
        </Row>
      </CardFooter>
    </Card>
  );
};

export default TicketStatus;

// const onPurchaseClick = () => {
// let t1 = null,
//   t2 = null;
// setProcessing(true);
// t1 = initTransaction({
//   date,
//   firstName: student.firstName,
//   isGuest: false,
//   isPurchase: true,
//   isRefund: false,
//   isStudent: true,
//   guestFirstName: "",
//   guestLastName: "",
//   lastName: student.lastName,
//   paymentType: paymentType,
//   price: price,
//   soldByName: fbUser.displayName,
//   soldByEmail: fbUser.email,
//   studentId: student.id,
//   ticketNumber: studentTicket,
// });
// student.studentTransaction = t1.key;
// student.isStudentTicketed = true;
// student.studentTicket = studentTicket;
// student.studentPrice = price;
// if (showGuest) {
//   t2 = initTransaction({
//     date,
//     firstName: student.firstName,
//     isGuest: true,
//     isPurchase: true,
//     isRefund: false,
//     isStudent: false,
//     guestFirstName: firstName,
//     guestLastName: lastName,
//     lastName: student.lastName,
//     paymentType: paymentType,
//     price: guestPrice,
//     soldByName: fbUser.displayName,
//     soldByEmail: fbUser.email,
//     studentId: student.id,
//     ticketNumber: guestTicket,
//   });
//   student.guestTransaction = t2.key;
//   student.guestPrice = guestPrice;
//   student.isGuestTicketed = true;
//   student.guestLastName = lastName;
//   student.guestFirstName = firstName;
//   student.guestTicket = guestTicket;
// }
// const db = firebaseDefault.firestore();
// const eventRef = db.collection(constants.EVENT_LIST_KEY).doc(eventKey);
// const queueRef = eventRef.collection(constants.QUEUE_LIST_KEY);
// const studentRef = eventRef
//   .collection(constants.STUDENT_LIST_KEY)
//   .doc(student.id);
// const t1Ref = eventRef
//   .collection(constants.TRANSACTION_LIST_KEY)
//   .doc(t1.key);
// const batch = db.batch();
// batch.set(studentRef, student);
// batch.set(queueRef.doc(t1.key), { status: "WAITING" });
// batch.set(t1Ref, t1);
// if (t2) {
//   const t2Ref = eventRef
//     .collection(constants.TRANSACTION_LIST_KEY)
//     .doc(t2.key);
//   batch.set(t2Ref, t2);
//   batch.set(queueRef.doc(t2.key), { status: "WAITING" });
// }
// batch.set(eventRef, event);
// batch
//   .commit()
//   .then(() => {
//     setProcessing(false);
//   })
//   .catch((err) => {
//     console.log(err);
//   });
// };

// useEffect(() => {
//   setDisablePurchaseButton(
//     studentTicket.length === 0 ||
//       paymentType.length === 0 ||
//       price.length === 0 ||
//       (showGuest &&
//         (guestTicket.length === 0 ||
//           guestPaymentType.length === 0 ||
//           guestPrice.length === 0 ||
//           lastName.length === 0 ||
//           firstName.length === 0))
//   );
// }, [
//   studentTicket,
//   paymentType,
//   price,
//   showGuest,
//   guestTicket,
//   guestPaymentType,
//   guestPrice,
//   lastName,
//   firstName,
// ]);
