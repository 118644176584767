import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Button, Card, CardHeader, CardBody } from "reactstrap";
import { download } from "../../utility/download";
import { format, fromUnixTime } from "date-fns";
import "./Roster.css";

const Roster = () => {
  const [event, setEvent] = useState(null);
  const [showCancelled, setShowCancelled] = useState(false);

  const transactionList = useSelector((state) => state.transactionList ?? []);
  const eventList = useSelector((state) => state.eventList);
  const eventKey = useSelector((state) => state.eventKey);

  useEffect(() => {
    if (!eventList || !eventKey) return;
    setEvent(eventList.find((e) => e.key === eventKey));
  }, [eventKey, eventList]);

  const sortFcn = (a, b) => {
    const aName = a.lastName + "," + a.firstName;
    const bName = b.lastName + "," + b.firstName;
    if (aName < bName) {
      return -1;
    } else if (aName > bName) {
      return 1;
    } else {
      return a.isGuest ? 1 : -1;
    }
  };

  let _transactionList = JSON.parse(JSON.stringify(transactionList));
  _transactionList = _transactionList.filter(
    (t) =>
      (t.cancelDate !== "" && showCancelled) ||
      (t.cancelDate === "" && !showCancelled)
  );
  _transactionList = _transactionList.sort(sortFcn);
  _transactionList = _transactionList.map((t) => [
    "☐",
    t.lastName,
    t.firstName,
    t.isGuest ? t.guestFirstName + " " + t.guestLastName : t.studentId,
    t.ticketNumber,
    t.transactionId,
    showCancelled
      ? format(fromUnixTime(t.cancelDate), "MMM d yyyy hh:mm a")
      : format(fromUnixTime(t.date), "MMM d yyyy hh:mm a"),
    t.notes
  ]);

  const downloadData = () => {
    const csv =
      "Present,Last name,First name,ID / Guest,Ticket number,Transaction ID," +
      (showCancelled ? "Cancel" : "Purchase") +
      " date,Notes\r\n" +
      _transactionList.map((t) => t.join(",")).join("\r\n");
    const filename = showCancelled
      ? "EventRoster-Cancelled.csv"
      : "EventRoster-Purchased.csv";
    download(csv, filename, "text/csv");
  };

  const toggleCancelled = (evt) => {
    setShowCancelled((c) => !c);
  };

  if (!event)
    return (
      <Card>
        <CardHeader>
          <Row>
            <Col md={2} className="roster-control-buttons">
              &nbsp;
            </Col>
            <Col md={8}>
              <h6>Loading rosters...</h6>
            </Col>
          </Row>
        </CardHeader>
      </Card>
    );

  return (
    <>
      <Card>
        <CardHeader>
          <Row>
            <Col md={2} className="roster-control-buttons">
              <Button color="primary" onClick={toggleCancelled}>
                {showCancelled ? "Show active" : "Show cancelled"}
              </Button>
            </Col>
            <Col md={8}>
              <h4>{event?.name}</h4>
              <h6>Event Roster ({showCancelled ? "Cancelled" : "Active"})</h6>
            </Col>
            <Col md={2} className="roster-control-buttons text-right">
              <Button color="primary" onClick={downloadData}>
                Download
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <table className="roster-table">
            <thead>
              <tr>
                <th className="text-center">Present</th>
                <th>Last name</th>
                <th>First name</th>
                <th>ID # / Guest name</th>
                <th className="text-center">Ticket number</th>
                <th className="text-center">Transaction ID</th>
                <th className="text-center">Purchase / Cancel Date</th>
                <th className="text-center">Notes</th>
              </tr>
            </thead>
            <tbody>
              {_transactionList.map((t) => (
                <tr key={t.key}>
                  <td className="text-center">
                    <span className="box-shape">{t[0]}</span>
                  </td>
                  <td>{t[1]}</td>
                  <td>{t[2]}</td>
                  <td>{t[3]}</td>
                  <td className="text-center">{t[4]}</td>
                  <td className="text-center">{t[5]}</td>
                  <td className="text-center">{t[6]}</td>
                  <td className="transaction-notes">{t[7]}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </CardBody>
      </Card>
    </>
  );
};

export default Roster;
