import as from "./actionStrings";
import produce from "immer";

const reducer = (state, action) => {
  const newState = produce(state, (draft) => {
    switch (action.type) {
      case as.SET_ID_TOKEN:
        draft.idToken = action.payload.idToken;
        break;

      case as.SET_LOP_DATA:
        draft.lopData = action.payload.lopData;
        break;

      // case as.SET_CLAIMS:
      //   draft.claims = action.payload.claims;
      //   break;

      case as.SET_EVENT_LIST:
        draft.eventList = action.payload.list;
        break;

      case as.SET_EVENT_KEY:
        draft.eventKey = action.payload.eventKey;
        draft.studentList = null;
        break;

      // case as.SET_OFFICE_LIST:
      //   draft.officeList = action.payload.list;
      //   break;
      //
      // case as.SET_CANDIDATE_LIST:
      //   draft.candidateList = action.payload.list;
      //   break;
      //
      // case as.SET_AUDIT_LIST:
      //   draft.auditList = action.payload.list;
      //   break;
      //
      case as.SET_STUDENT_LIST:
        draft.studentList = action.payload.list;
        break;

      case as.CLEAR_ELECTION_SELECTED:
        draft.selectedEvent = null;
        break;

      case as.ERROR:
        draft.errorCode = action.payload.errorCode;
        break;

      case as.SET_FB_USER:
        draft.fbUser = action.payload.fbUser;
        draft.fbAdditionalUserInfo = action.payload.fbAdditionalUserInfo;
        draft.isLoggedIn = true;
        break;

      case as.SET_FIRESTORE_USER:
        draft.fsUser = action.payload.fsUser;
        break;

      case as.INVALID_USER:
        draft.unauthorized = true;
        break;

      case as.SET_USER_LIST:
        draft.userList = action.payload.list;
        break;

      case as.SET_TRANSACTION_LIST:
        draft.transactionList = action.payload.list;
        break;

      default:
        break;
    }
  });

  return newState;
};

export default reducer;
