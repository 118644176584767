import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Row,
  Col,
  Form,
  FormGroup,
  FormFeedback,
  Label,
  Input,
  Button,
} from "reactstrap";
import "./Students.css";
import constants from "../../utility/constants";
import firebase from "firebase/app";
import initStudent from "../../models/initStudent";

const Students = () => {
  const [newStudent, setNewStudent] = useState(false);
  const [id, setId] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [grade, setGrade] = useState("");
  const [classOf, setClassOf] = useState("");
  const [password, setPassword] = useState("");
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);
  const [isDupe, setIsDupe] = useState(false);
  const [studentAdded, setStudentAdded] = useState(false);

  const [db] = useState(firebase.firestore());

  const studentList = useSelector((state) => state.studentList);
  const eventKey = useSelector((state) => state.eventKey);

  useEffect(() => {
    setDisableSaveBtn(
      !newStudent ||
        isDupe ||
        id.length === 0 ||
        lastName.length === 0 ||
        firstName.length === 0 ||
        grade.length === 0 ||
        classOf.length === 0 ||
        password.length === 0
    );
  }, [
    newStudent,
    lastName.length,
    firstName.length,
    grade.length,
    classOf.length,
    isDupe,
    id.length,
    password.length,
  ]);

  useEffect(() => {
    const s = studentList.find((s) => s.id === id);
    if (s) {
      setIsDupe(true);
    } else {
      setIsDupe(false);
    }
  }, [id, studentList]);

  const onChange = (setter) => (evt) => {
    setter(evt.currentTarget.value);
  };

  const add = () => {
    setFirstName("");
    setLastName("");
    setId("");
    setGrade("");
    setClassOf("");
    setNewStudent(true);
    setPassword("");
  };

  const save = () => {
    const newStudent = initStudent([
      id,
      lastName,
      firstName,
      grade,
      classOf,
      password,
    ]);
    setNewStudent(false);
    db.collection(constants.EVENT_LIST_KEY)
      .doc(eventKey)
      .collection(constants.STUDENT_LIST_KEY)
      .doc(id)
      .set({ ...newStudent })
      .then(() => {
        setNewStudent(false);
        setStudentAdded(true);
      })
      .catch((err) => {
        console.log(err);
        setNewStudent(true);
        setStudentAdded(false);
      });
  };

  const cancel = () => {
    setNewStudent(false);
  };

  return (
    <Row className="candidate-panel">
      <Col md={{ size: 8, offset: 4 }}>
        <Row className="bottom-spacer">
          <Col md={4}>
            <Button
              onClick={add}
              color="primary"
              disabled={newStudent}
              className="control-buttons"
            >
              Add student
            </Button>
          </Col>
          <Col md={4}>
            {studentAdded ? (
              <h6 className="new-student-added">The new student was added</h6>
            ) : null}
          </Col>
          <Col md={4} className="text-right">
            <Button
              onClick={save}
              color="primary"
              disabled={!newStudent || disableSaveBtn}
              className="control-buttons"
            >
              Save
            </Button>
            <Button onClick={cancel} color="primary">
              Cancel
            </Button>
          </Col>
        </Row>
        {newStudent ? (
          <Form>
            <FormGroup row>
              <Label for="id" md={3}>
                Student ID:
              </Label>
              <Col md={5}>
                <Input
                  invalid={isDupe}
                  type="text"
                  name="id"
                  id="id"
                  onChange={onChange(setId)}
                  value={id}
                  placeholder="Student ID"
                />
                <FormFeedback>Duplicate student ID</FormFeedback>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="lastName" md={3}>
                Last name:
              </Label>
              <Col md={5}>
                <Input
                  type="text"
                  name="lastName"
                  id="lastName"
                  onChange={onChange(setLastName)}
                  value={lastName}
                  placeholder="Last name"
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="firstName" md={3}>
                First name:
              </Label>
              <Col md={5}>
                <Input
                  type="text"
                  name="firstName"
                  id="firstName"
                  onChange={onChange(setFirstName)}
                  value={firstName}
                  placeholder="First name"
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="grade" md={3}>
                Grade:
              </Label>
              <Col md={5}>
                <Input
                  type="text"
                  name="grade"
                  id="grade"
                  onChange={onChange(setGrade)}
                  value={grade}
                  placeholder="Grade"
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="classOf" md={3}>
                Class of:
              </Label>
              <Col md={5}>
                <Input
                  type="text"
                  name="classOf"
                  id="classOf"
                  onChange={onChange(setClassOf)}
                  value={classOf}
                  placeholder="Class of"
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="password" md={3}>
                Password:
              </Label>
              <Col md={5}>
                <Input
                  type="text"
                  name="password"
                  id="password"
                  onChange={onChange(setPassword)}
                  value={password}
                  placeholder="Password"
                />
              </Col>
            </FormGroup>
          </Form>
        ) : null}
      </Col>
    </Row>
  );
};

export default Students;
