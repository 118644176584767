/**
 * Generate the Firestore ref with the provided keys
 * @param db      Firestore db object
 * @param keys    Keys alternating with .collection and .doc
 * @returns {*}
 */
export function getRef(db, keys) {
  function oneLevel(ref, keys, type) {
    if (keys.length === 0) {
      return ref;
    } else {
      return oneLevel(
        type === 'collection' ? ref.collection(keys[0]) : ref.doc(keys[0]),
        keys.slice(1),
        type === 'collection' ? 'doc' : 'collection'
      )
    }
  }
  return oneLevel(db, keys, 'collection');
}

