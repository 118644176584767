import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import as from "../../reducer/actionStrings";
import actionCreator from "../../reducer/actionCreator";
import constants from "../../utility/constants";
import "firebase/database";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Input,
  Label,
  Button,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Information from "../Information/Information";
import StudentImport from "../StudentImport/StudentImport";
import Sales from "../Sales/Sales";
import Settings from "../Settings/Settings";
import Students from "../Students/Students";
import initEvent from "../../models/initEvent";
import "./Event.css";
import Users from "../Users/Users";
import actionStrings from "../../reducer/actionStrings";
import Roster from "../Roster/Roster";
import Audit from "../Audit/Audit";
// import useProcessEmailQueue from "../../hooks/useProcessEmailQueue";
const URL =
  "https://us-central1-asb-tickets-216b8.cloudfunctions.net/getLopStudents";

const Event = () => {
  const [windowMode, setWindowMode] = useState("sales"); // 'candidateList" | 'voterList'
  const [event, setEvent] = useState(null);
  const firebaseDefault = useSelector((state) => state.firebaseDefault);

  const db = firebaseDefault.firestore();
  const dispatch = useDispatch();
  const eventList = useSelector((state) => state.eventList);
  const eventKey = useSelector((state) => state.eventKey);
  const fbUser = useSelector((state) => state.fbUser);
  const fsUser = useSelector((state) => state.fsUser);
  const idToken = useSelector((state) => state.idToken);

  // useProcessEmailQueue(firebaseDefault, event, fbUser);

  useEffect(() => {
    let event = eventList?.find((event) => event.key === eventKey);
    setEvent(event);
  }, [eventKey, eventList]);

  useEffect(() => {
    if (!idToken) return;
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    fetch(URL, {
      method: "POST",
      body: JSON.stringify({ token: idToken }),
      headers,
    })
      .then((res) => res.json())
      .then((data) => {
        if (!data.studentList || !data.settings) return;
        const keys = Object.keys(data.studentList);
        const lopData = keys.reduce((o, key) => {
          const student = data.studentList[key];
          const lopCount = student.lopCount;
          const attCount = student.attendance ? Object.keys(data.studentList[key].attendance).length : 0;
          o[key] = {
            id: data.studentList[key].stuID,
            acGPA: data.studentList[key].acGPA,
            citGPA: data.studentList[key].citGPA,
            A: data.studentList[key].A,
            T: data.studentList[key].T,
            Z: data.studentList[key].Z,
            lopRecords: lopCount,
            attCount,
            onLOP:
              parseFloat(data.studentList[key].acGPA) < 2.0 ||
              parseFloat(data.studentList[key].citGPA) < 2.0 ||
              lopCount > 0 || //attCount > 0
              // 2024-04-29 Add back the A/T/Z settings removed 2022-03-05
              parseInt(data.studentList[key].A) > data.settings.A ||
              parseInt(data.studentList[key].T) > data.settings.T ||
              parseInt(data.studentList[key].Z) > data.settings.Z
          };
          return o;
        }, {});
        dispatch(actionCreator(actionStrings.SET_LOP_DATA, { lopData }));
      })
      .catch((err) => console.log(err));
  }, [idToken, dispatch]);

  const selectEventClick = (evt) => {
    let eventKey = evt.currentTarget.value;
    setWindowMode("eventInformation");
    dispatch(actionCreator(as.SET_EVENT_KEY, { eventKey }));
  };

  const changeWindowMode = (evt) => {
    setWindowMode(evt.currentTarget.value);
  };

  const add = () => {
    let eventRef = db.collection(constants.EVENT_LIST_KEY).doc();
    let newEvent = initEvent(eventRef.id, fbUser.uid);
    eventRef
      .set(newEvent)
      .then(() => {
        dispatch(actionCreator(as.SET_EVENT_KEY, { eventKey: newEvent.key }));
        setWindowMode("eventInformation");
      })
      .catch((error) => {
        dispatch(actionCreator(as.ERROR, { errorCode: error.code }));
      });
  };

  const test = () => {
    // fetch("https://osps.sandi.net/asbevents/sendemail.php?secret=abc")
    //   .then((res) => res.text())
    //   .then((res) => console.log(res));
  };

  const getContents = () => {
    if (!eventList) {
      return (
        <Row>
          <Col>
            <h2 className="text-center">Loading event information...</h2>
          </Col>
        </Row>
      );
    } else if (windowMode === "settings") {
      return <Settings db={db} />;
    } else if (windowMode === "users") {
      return <Users db={db} />;
    } else if (event) {
      if (windowMode === "eventInformation") {
        return <Information />;
      } else if (windowMode === "sales") {
        // return null;
        return <Sales db={db} />;
      } else if (windowMode === "studentList") {
        return <StudentImport db={db} />;
      } else if (windowMode === "students") {
        return <Students db={db} />;
      } else if (windowMode === "roster") {
        return <Roster db={db} />;
      } else if (windowMode === "audit") {
        return <Audit db={db} />;
        // } else if (windowMode === "settings") {
        //   return <Settings db={db} />;
      } else {
        return null;
      }
    } else {
      return (
        <Row>
          <Col>
            <h2 className="text-center" onClick={test}>
              Select an event...
            </h2>
          </Col>
        </Row>
      );
    }
  };

  return (
    <Card>
      <Header />
      <Card>
        <CardHeader className="event-header">
          <Row className="event-separator">
            <Col md={2} className="text-right label-center">
              <Label>Event: </Label>
            </Col>
            <Col md={4}>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <Button block color="primary" onClick={add}>
                    New
                  </Button>
                </InputGroupAddon>
                <Input
                  type="select"
                  name="event"
                  id="event"
                  value={eventKey ? eventKey : ""}
                  onChange={selectEventClick}
                >
                  <option value="">Select an event...</option>
                  {eventList
                    ? eventList.map((event) => {
                        return (
                          <option key={event.key} value={event.key}>
                            {event.name}
                          </option>
                        );
                      })
                    : null}
                </Input>
              </InputGroup>
            </Col>
            <Col md={2} className="text-right label-center">
              <Label>Page:</Label>
            </Col>
            <Col md={3}>
              <Input
                type="select"
                name="windowMode"
                id="event"
                value={windowMode}
                onChange={changeWindowMode}
              >
                {!event && (
                  <option disabled={event} value="">
                    &nbsp;
                  </option>
                )}
                <option disabled={!event} value="sales">
                  Sales
                </option>
                <option disabled={!event} value="eventInformation">
                  Event information
                </option>
                <option disabled={!event} value="studentList">
                  Student upload
                </option>
                <option disabled={!event} value="students">
                  Add students
                </option>
                <option disabled={!event} value="roster">
                  Roster
                </option>
                <option disabled={!event} value="audit">
                  Transactions
                </option>
                {fsUser && fsUser.admin ? (
                  <option value="users">Users</option>
                ) : null}

                {/*{claims.firebase.sign_in_provider === "password" ? (*/}
                {/*  <option value="settings">Change password</option>*/}
                {/*) : null}*/}
              </Input>
            </Col>
          </Row>
        </CardHeader>
        <CardBody className="app-min-height">{getContents()}</CardBody>
      </Card>
      <Footer />
    </Card>
  );
};

export default Event;
