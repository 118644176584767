import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Form,
  FormGroup,
  Badge,
} from "reactstrap";
import "./Sales.css";
import Ticket from "../Ticket/Ticket";
import Transactions from "../Transactions/Transactions";

const Sales = () => {
  const [filter, setFilter] = useState("");
  const [student, setStudent] = useState(null);
  const [lop, setLop] = useState(null);
  const [event, setEvent] = useState(null);

  const studentList = useSelector((state) => state.studentList ?? []);
  const lopData = useSelector((state) => state.lopData);
  const transactionList = useSelector((state) => state.transactionList ?? []);
  const eventList = useSelector((state) => state.eventList);
  const eventKey = useSelector((state) => state.eventKey);

  useEffect(() => {
    if (!eventList || !eventKey) return;
    setEvent(eventList.find((e) => e.key === eventKey));
  }, [eventKey, eventList]);

  const sortFcn = (a, b) =>
    a.lastName.toLowerCase() + "," + a.firstName.toLowerCase() <
    b.lastName.toLowerCase() + "," + b.firstName.toLowerCase()
      ? -1
      : 1;

  const filterFcn = (s) =>
    s.lastName.toLowerCase().includes(filter.toLowerCase()) ||
    s.firstName.toLowerCase().includes(filter.toLowerCase()) ||
    s.id.toLowerCase().includes(filter.toLowerCase());

  let _studentList = JSON.parse(JSON.stringify(studentList));
  _studentList = _studentList.filter(filterFcn);
  _studentList.sort(sortFcn);

  const onFilterChange = (evt) => {
    setFilter(evt.currentTarget.value);
  };

  const selectStudent = (evt) => {
    const id = evt.currentTarget.getAttribute("data-id");
    setStudent(_studentList.find((s) => s.id === id));
  };

  useEffect(() => {
    if (lopData && student?.id) {
      setLop(lopData[student.id]);
    }
  }, [student?.id, lopData]);

  return (
    <Row>
      <Col md={4}>
        <Card>
          <CardHeader>
            <div className="student-list-header">Student list</div>
          </CardHeader>
          <CardBody>
            <Form inline className="student-list-filter-container">
              <FormGroup>
                <Label className="mr-md-4">Filter: </Label>
                <Input
                  className="student-list-filter"
                  type="text"
                  onChange={onFilterChange}
                  value={filter}
                />
              </FormGroup>
            </Form>
            <div className="student-list-container">
              <ListGroup>
                {_studentList.map(($student) => {
                  const attCount =
                    ($student.studentTransaction.length > 0 ? 1 : 0) +
                    ($student.guestTransaction.length > 0 ? 1 : 0);
                  return (
                    <ListGroupItem
                      key={$student.id}
                      onClick={selectStudent}
                      data-id={$student.id}
                      className="list-item"
                      active={$student.id === student?.id}
                    >
                      <Row>
                        <Col
                          md={8}
                          className={
                            lopData && lopData[$student.id]?.onLOP
                              ? "student-list-item on-lop"
                              : "student-list-item"
                          }
                        >
                          {$student.lastName}, {$student.firstName} (
                          {$student.id})
                        </Col>
                        <Col
                          md={4}
                          className="student-list-item student-list-item-badge"
                        >
                          {attCount > 0 ? (
                            <Badge color="secondary">{attCount}</Badge>
                          ) : null}
                          {lopData && lopData[$student.id]?.onLOP ? (
                            <Badge color="danger">LOP</Badge>
                          ) : null}
                          {!$student.active ? (
                            <Badge color="warning">Inactive</Badge>
                          ) : null}
                        </Col>
                      </Row>{" "}
                    </ListGroupItem>
                  );
                })}
              </ListGroup>
            </div>
          </CardBody>
        </Card>
      </Col>
      {student ? (
        <Col md={8}>
          <Row>
            <Col md={12}>
              <Ticket lop={lop} student={student} event={event} />
            </Col>
          </Row>
          <Row className="transaction-history">
            <Col md={12}>
              <Transactions
                student={student}
                transactionList={transactionList}
                event={event}
              />
            </Col>
          </Row>
        </Col>
      ) : (
        <Col md={8}>
          <h5 className="text-center">Please select a student</h5>
        </Col>
      )}
    </Row>
  );
};

export default Sales;
