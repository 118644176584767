const TRIAL_PERIOD = 365;
const TOKEN_EXPIRE_TIME = 120;
const USER_LIST_KEY = "userList";
const EVENT_LIST_KEY = "eventList";
const QUEUE_LIST_KEY = "queue";
const STUDENT_LIST_KEY = "studentList";
const BID_LIST_KEY = "candidateList";
const OFFICE_LIST_KEY = "officeList";
const TRANSACTION_LIST_KEY = "transactionList";
const AUTH_MODES = [
  { value: "password", text: "Password" },
  { value: "google", text: "Google" },
];
const SALT_LENGTH = 15;
const KEY_SIZE = 10;

module.exports = {
  TRIAL_PERIOD,
  USER_LIST_KEY,
  EVENT_LIST_KEY,
  STUDENT_LIST_KEY,
  OFFICE_LIST_KEY,
  BID_LIST_KEY,
  TRANSACTION_LIST_KEY,
  TOKEN_EXPIRE_TIME,
  AUTH_MODES,
  SALT_LENGTH,
  KEY_SIZE,
  QUEUE_LIST_KEY,
};
