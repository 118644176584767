import React from "react";
import { Table, Button } from "reactstrap";
import "./Transactions.css";
import { useSelector } from "react-redux";
import { format, fromUnixTime } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import refundComplete from "../../utility/refundComplete";

const Transactions = ({ student, transactionList, event }) => {
  const firebaseDefault = useSelector((state) => state.firebaseDefault);

  let _transactionList = JSON.parse(JSON.stringify(transactionList));
  _transactionList = _transactionList.filter((t) => t.studentId === student.id);
  _transactionList = _transactionList.sort((a, b) =>
    a.date < b.date ? 1 : -1
  );

  const toggleComplete = (evt) => {
    const transactionId = evt.currentTarget.getAttribute("data-id");
    const transaction = transactionList.find((t) => transactionId === t.key);
    refundComplete(firebaseDefault, event, transaction);
  };

  const formatDateTime = (date) => {
    return (
      <>
        {format(fromUnixTime(date), "MM/dd/yyyy")}
        <br />
        {format(fromUnixTime(date), "hh:mm:ss a")}
      </>
    );
  };

  if (_transactionList.length === 0) {
    return (
      <h6 className="no-transactions">
        There are no transactions for this student
      </h6>
    );
  }

  return (
    <Table bordered size="sm">
      <thead>
        <tr>
          <th colSpan={9} className="transaction-history-header">
            Transaction history for {student.firstName} {student.lastName}
          </th>
        </tr>
        <tr>
          <th>Date</th>
          <th>Ticket #</th>
          <th>Transaction ID</th>
          <th>For whom?</th>
          <th>Pmt type</th>
          <th>Amt</th>
          <th title="Is ticket active, or cancelled?">Status</th>
          <th>Cancel date / by</th>
          <th width="130px">Refund date</th>
        </tr>
      </thead>
      <tbody>
        {_transactionList.map((t) => {
          return (
            <tr
              key={t.transactionId}
              className={t.cancelDate !== "" ? "ticket-cancelled" : ""}
            >
              <td>{formatDateTime(t.date)}</td>
              <td>{t.ticketNumber}</td>
              <td>{t.transactionId}</td>
              <td>{t.isStudent ? "Student" : t.isGuest ? "Guest" : "---"}</td>
              <td>{t.paymentType}</td>
              <td>$ {parseFloat(t.price).toFixed(2)}</td>
              <td>
                {t.cancelDate !== "" ? (
                  <FontAwesomeIcon icon={faXmark} />
                ) : (
                  <FontAwesomeIcon icon={faCheck} />
                )}
              </td>
              <td>
                {t.cancelDate !== "" ? (
                  <>
                    <div>{formatDateTime(t.cancelDate)}</div>
                    <div>{t.cancelledBy ?? ""}</div>
                  </>
                ) : (
                  <span>&nbsp;</span>
                )}
              </td>
              <td>
                {t.refundDate === "" && t.cancelDate !== "" ? (
                  <Button
                    size="sm"
                    color={t.complete ? "success" : "danger"}
                    onClick={toggleComplete}
                    data-id={t.key}
                    data-val={t.complete ? "true" : "false"}
                    className="resend-button complete-button"
                  >
                    {t.complete ? "Complete" : "Pending"}
                  </Button>
                ) : t.refundDate !== "" ? (
                  formatDateTime(t.refundDate)
                ) : (
                  ""
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default Transactions;
