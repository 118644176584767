const actionStrings = {
  LOGIN_SUCCESSFUL: "loginSuccessful",
  INVALID_LOGIN_FLOW: "invalidLoginFlow",
  SET_WRONG_ROLE: "setWrongRole",
  SET_ID_TOKEN: "setIdToken",
  SET_LOGIN_DATA: "setLoginData",
  SET_CLAIMS: "setClaims",
  SET_FB_USER: "setFbUser",
  SET_FIRESTORE_USER: "setFirestoreUser",
  SET_EVENT_LIST: "setEventList",
  SET_TRANSACTION_LIST: "setTransactionList",
  SET_USER_LIST: "setUserList",
  SET_LOP_DATA: "setLopData",

  USER_SELECTED: "userSelected",
  CLEAR_USER_SELECTED: "clearUserSelected",
  INVALID_USER: "invalidUser",

  SET_EVENT_KEY: "setEventKey",
  EVENT_SELECTED: "eventSelected",
  EVENT_FOUND: "eventFound",
  CLEAR_EVENT_SELECTED: "clearEventSelected",
  SET_AUDIT_LIST: "setAuditList",
  SET_STUDENT_LIST: "setStudentList",
  CLEAR_ELECTION_SELECTED: "clearElectionSelected",

  SET_OFFICE_LIST: "setOfficeList",
  OFFICE_SELECTED: "officeSelected",
  OFFICE_FOUND: "officeFound",

  CANDIDATE_SELECTED: "candidateSelected",
  SET_CANDIDATE_LIST: "setCandidateList",

  ERROR: "programError",
};

export default actionStrings;
