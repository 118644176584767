import React from "react";
import { CardHeader } from "reactstrap";
import "./Header.css";

const Header = () => {
  return (
    <CardHeader className="text-center">
      <h1>ASB Event Ticketing</h1>
    </CardHeader>
  );
};

export default Header;
