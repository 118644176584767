import React from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import reducer from "./reducer/reducer";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import initialState from "./reducer/initialState";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { firebaseConfig } from "./credentials-registrar";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";

import App from "./components/App/App";
import reportWebVitals from "./utility/reportWebVitals";
import ErrorHandler from "./components/ErrorHandler/ErrorHandler";

const initializeFirebase = (defaultConfig) => {
  let firebaseDefault;
  try {
    firebaseDefault = firebase.app();
  } catch (e) {
    firebaseDefault = firebase.initializeApp(defaultConfig);
  }
  return { firebaseDefault };
};

const { firebaseDefault } = initializeFirebase(firebaseConfig);

firebase.auth().signOut();

const middlewares = [thunkMiddleware];
const middlewareEnhancer = applyMiddleware(...middlewares);
const enhancers = [middlewareEnhancer];
const composedEnhancer = composeWithDevTools(...enhancers);

const store = createStore(
  reducer,
  initialState(firebaseDefault),
  composedEnhancer
);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ErrorHandler>
        <App />
      </ErrorHandler>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
